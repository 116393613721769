import * as React from "react"
import { API_INDEXES_PL } from "../global/api"

import LayoutSubpage from "../layouts/layout_subpage"
import Indexes from "../components/Indexes/Indexes"

const IndexPage = () => (
  <LayoutSubpage title="Podstawowy indeks haseł">
    <Indexes
      dataUrl={API_INDEXES_PL}
      title="Podstawowy indeks haseł"
      language="pl"
    />
  </LayoutSubpage>
)

export default IndexPage
